export function setupMenu(toggleButtonId, menuContentId, menuIconId, closeIconId) {
    const menuContent = document.getElementById(menuContentId);
    const toggleMenuButton = document.getElementById(toggleButtonId);
    const menuIcon  = document.getElementById(menuIconId);
    const closeIcon  = document.getElementById(closeIconId);


    console.log(toggleMenuButton, menuContent);
    if (toggleMenuButton && menuContent) {
        toggleMenuButton.addEventListener('click', () => {
            menuContent.classList.toggle('show');

            // Swap the icons
            menuIcon.classList.toggle('hidden');
            closeIcon.classList.toggle('hidden');

            // Add a smooth rotation effect
            menuIcon.classList.toggle('animate-fade-in');
            closeIcon.classList.toggle('animate-fade-in');
        });
    } else {
        console.error(`Menu setup failed: Elements with IDs '${toggleButtonId}' or '${menuContentId}' not found.`);
    }
}